<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
    style="overflow: hidden;"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.patient') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body" style="overflow-y: auto;
max-height: calc(100vh - 150px);">
            <input
              type="hidden"
              class="form-control"
              v-model.number="patient.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.name') }}:<span style="color:red;">*</span></label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="patient.patientName"
                  placeholder="Nama Pasien"
                  v-validate="'required'"
                  data-vv-as="Nama Pasien"
                  name="patientName"
                  :class="{'is-invalid': errors.has('patientName') }"
                />
                <div
                  v-show="errors.first('patientName')"
                  class="invalid-feedback"
                >{{ errors.first('patientName') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Tempat Lahir :</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="patient.cityOfBirth"
                  placeholder="Tempat Lahir Pasien"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.tanggalLahir') }}:<span style="color:red;">*</span></label>
              <div class="col-lg-6">
                <date-picker
                  ref="datePickerVue"
                  :changedValue.sync="patient.dateOfBirth"
                  :id-date-picker="'dob_' + patient.id"
                  :picker-value="patient.dateOfBirth"
                  place-holder="DD/MM/YYYY"
                  :is-validate="true"
                  start-date=""
                  end-date="-0d"
                ></date-picker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.gender') }}:<span style="color:red;">*</span></label>
              <div class="col-lg-6">
                <gender-option
                  v-model="patient.jenisKelamin"
                  :is-validate="true"
                ></gender-option>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.phoneNo') }}:<span style="color:red;">*</span></label>
              <div class="col-lg-6">
                <phone-number-patient
                  :data="patient.mobilePhone1"
                  :is-validate="true"
                  :is-disabled="checkPermission('Pasien.Dialog.Enabled.Telepon') ? false : true"
                  :oldPhoneNum="tempMobilePhone1"
                  :changedValue.sync="patient.mobilePhone1"
                  :phone-patient="true"
                  :edit-mode="(patient.id != null) ? true : false"
                  :is-country-component="patient.id ? false : true"
                >
                </phone-number-patient>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.email') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="patient.email"
                  placeholder="Email Pasien"
                  v-validate="'email'"
                  data-vv-as="Email Pasien"
                  name="email"
                  :class="{'is-invalid': errors.has('email') }"
                />
                <div
                  v-show="errors.first('email')"
                  class="invalid-feedback"
                >{{ errors.first('email') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.medicalNo') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="patient.medicalNo"
                  placeholder="No Rekam Medik Pasien"
                  disabled
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Golongan Darah :</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="patient.golDarah"
                  placeholder="Golongan Darah Pasien"
                />
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-lg-4 col-form-label">Agama :</label>
              <div class="col-lg-6">
                <div class="form-group">
                  <vue-multi-select
                    v-model="selectedReligion"
                    track-by="id"
                    label="name"
                    :options="religionsData"
                    name="Agama"
                    id="selectedReligion"
                  >
                  </vue-multi-select>
                </div>
              </div>
            </div>
            <div class="form-group row mb-0">
              <label class="col-lg-4 col-form-label">Etnis :</label>
              <div class="col-lg-6">
                <div class="form-group">
                  <vue-multi-select
                    v-model="selectedEtnic"
                    track-by="name"
                    label="name"
                    :options="etnicData"
                  >
                  </vue-multi-select>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">Pendidikan :</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="patient.pendidikan"
                  placeholder="Pendidikan Pasien"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">SSN :</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="patient.ssn"
                  placeholder="SSN"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.userId') }}:</label>
              <label class="col-lg-4 col-form-label">User Patient:</label>
              <div class="col-lg-6 row">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Login</th>
                      <th scope="col">Sebagai</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="userPatient, index in patient.patientUserPatient" :key="index">
                      <td scope="row">{{ userPatient.userId }}</td>
                      <td>{{ userPatient.phoneNumber }}</td>
                      <td>{{ userPatient.familyType }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="form-group row" v-if="selectedValue">
              <label class="col-lg-4 col-form-label">Family Type :</label>
              <div class="col-lg-6">
                <select v-model="selectedType" name="familyType" id="familyType" class="form-control">
                  <option v-for="(type, i) in familyType" :key="i">{{ type }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
               <span class="col-lg-4">Tanda (<span style="color:red;">*</span>) wajib untuk diisi.</span>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../../_general/DatePicker";
// import SelectPicker from "../../_general/SelectPicker";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PatientRepository = RepositoryFactory.get("patient");
// const UserRepository = RepositoryFactory.get("user");
const PatientUserPatientRepository = RepositoryFactory.get("patientUserPatient");
// import { Validator } from 'vee-validate';
import VeeValidate from 'vee-validate';
import PhoneNumberPatient from "../../_general/PhoneNumberWithCountry"
// import { isNullOrUndefined } from 'util';
import Pasien from "../../../model/pasien-model";
import { mapState, mapMutations, mapActions } from 'vuex';
import GenderOption from "./../../_general/GenderOption";
import MultiSelect from 'vue-multiselect';
import FamilyType from './../../../constants/family-type-enum';
import VueMultiSelect from './../../_select/VueMultiSelect';
import CheckPermission from "./../../../services/checkPermission.service";
const checkPermission = new CheckPermission();

VeeValidate.setMode('passive');
export default {
  components: {
    DatePicker,
    PhoneNumberPatient,
    GenderOption,
    MultiSelect,
    VueMultiSelect
  },
  data() {
    return {
      patient: new Pasien(),
      tempMobilePhone1: null,
      userPatient: null,
      userPatients: [],
      selectedValue: '',
      familyType: FamilyType,
      selectedType: '',
      loading: false,
      religionsData: [],
      etnicData: [],
      selectedReligion: null,
      selectedEtnic: null,
      selectedReligionValidation: false,
    };
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed: {
    ...mapState('MasterStore', {
      modalName: state => state.modalName,
      dataEdit: state => state.dataEdit,
      patientUserPatient: state => state.patientUserPatient
    }),
  },
  methods: {
    ...mapActions({
      createNew: 'MasterStore/createNewServer',
      updateById: 'MasterStore/updateByIdServer'
    }),
    ...mapMutations({
      clearData: 'MasterStore/CLEAR_DATA'
    }),
    checkPermission: function (method) {
      return checkPermission.isCanAccess(method);
    },
    updateData: function () {
      let vx = this
      if (vx.patientUserPatient) {
        vx.patient = Object.assign({}, vx.patientUserPatient);
        if (vx.patient.agama) {
          vx.selectedReligion = vx.patient.agama
        }
        if(vx.patient.jenisKelamin) {
          vx.patient.gender = vx.patient.jenisKelamin
          vx.patient.jenisKelamin = vx.patient.jenisKelamin.initial
        }
        if(vx.patient.etnis) {
          vx.selectedEtnic = vx.patient.etnis
        }
      }
    },
    submitData: function () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.patient.id) {
            if (typeof this.patient.jenisKelamin == 'string') {
              this.patient.jenisKelamin = this.patient.gender
            }
            const payload = this.addPatientData(this.patient, this.selectedReligion, this.selectedEtnic)
            this.updateById({ repository: PatientRepository.updatePatientNew, data: payload, table: this.$parent });
          } else {
            const payload = this.addPatientData(this.patient, this.selectedReligion, this.selectedEtnic)
            this.createNew({ repository: PatientRepository.createPatientNew, data: payload, table: this.$parent});
          }
          return;
        }
      });
    },
    debounceInput: _.debounce(function (input) {
      if(input.length > 3){
        this.loading = true
        // this.searchUserPatient(input)
      }
    }, 500),
    searchUserPatient(input){
      new Promise((resolve, reject) => {
        PatientUserPatientRepository.getUserPatient({'phoneNumber.contains': input}, resolve, reject)
      })
      .then(({ data }) => {
        this.loading = false
        this.userPatients = data
      })
      .catch(error => {
        toastr.error('Pasien tidak memiliki user')
      })
    },
    customLabel({ id, phoneNumber }) {
      return `${id} - ${phoneNumber}`
    },
    select(e){
      this.selectedValue = e
    },
    remove(e){
      this.selectedValue = ''
    },
    getReligions(){
      new Promise((resolve, reject) => {
        PatientRepository.getReligions({}, resolve, reject)
      })
      .then(({ data }) => {
        this.religionsData = data
      })
      .catch(error => {
        toastr.error('Data agama tidak dapat ditemukan')
      })
    },
    getEtnicity(){
      new Promise((resolve, reject) => {
        PatientRepository.getEtnicity({}, resolve, reject)
      })
      .then(({ data }) => {
        this.etnicData = data
      })
      .catch(error => {
        toastr.error('Data etnis tidak dapat ditemukan')
      })
    },
    addPatientData (patient, religion, etnis) {
      const data = {
        ...(patient.id ? {id: patient.id} : {}),
        ...(religion ? {agama: {id: religion.id, name: religion.name}} : {}),
        ...(patient.cityOfBirth ? {cityOfBirth: patient.cityOfBirth} : {}),
        ...(patient.dateOfBirth ? {dateOfBirth: patient.dateOfBirth} : {}),
        ...(patient.email ? {email: patient.email} : {}),
        ...(etnis ? {etnis: {id: etnis.id, name: etnis.name}} : {}),
        ...(patient.golDarah ? {golDarah: patient.golDarah} : {}),
        ...(patient.jenisKelamin ? {jenisKelamin: {id: patient.jenisKelamin.id, initial: patient.jenisKelamin.initial}} : {}),
        ...(patient.mobilePhone1 ? {mobilePhone: patient.mobilePhone1} : {}),
        ...(patient.patientName ? {patientName: patient.patientName} : {}),
        ...(patient.pendidikan ? {pendidikan: patient.pendidikan} : {}),
        ...(patient.ssn ? {ssn: patient.ssn} : {}),
        }
        return data
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    this.updateData();
    // hit patient-user-patient -> patientId.equals = patientId
    if(this.dataEdit){
      this.tempMobilePhone1 = this.dataEdit.mobilePhone1
      // this.getPatientUserPatient(this.dataEdit.id)
    }
    this.getReligions()
    this.getEtnicity()
  }
};
</script>
